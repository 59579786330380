<template>
    <div class="container">
        <div class="error">
            <div class="container-fluid">
                <div class="col-xs-12 ground-color text-center">
                    <div class="container-error-404">
                        <div class="clip">
                            <div class="shadow">
                                <span class="digit thirdDigit">{{ error.code[0] }}</span>
                            </div>
                        </div>
                        <div class="clip">
                            <div class="shadow">
                                <span class="digit secondDigit">{{ error.code[1] }}</span>
                            </div>
                        </div>
                        <div class="clip">
                            <div class="shadow">
                                <span class="digit firstDigit">{{ error.code[2] }}</span>
                            </div>
                        </div>
                        <div class="msg">
                            OH!
                            <span class="triangle"></span>
                        </div>
                    </div>
                    <h2 class="h1">{{ error.msg }}</h2>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            error: {
                code: [],
                msg: ""
            }
        };
    },
    mounted() {
        this.error = this.getErrorMessage();
        if (this.error.msg == "") {
            this.error.code = [4, 0, 4];
            this.error.msg = "Sorry! Page not found";
        }
    }
};
</script>
<style lang="scss">
.error .clip .shadow {
    height: 100%;
}
.error .clip:nth-of-type(2) .shadow {
    width: 130px;
}
.error .clip:nth-of-type(1) .shadow,
.error .clip:nth-of-type(3) .shadow {
    width: 250px;
}
.error .digit {
    width: 150px;
    height: 150px;
    line-height: 150px;
    font-size: 120px;
    font-weight: bold;
}
.error h2 {
    font-size: 32px;
}
.error .msg {
    top: -190px;
    left: 30%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 32px;
}
.error span.triangle {
    top: 70%;
    right: 0%;
    border-left: 20px solid #535353;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
}

.error .container-error-404 {
    margin-top: 10%;
    position: relative;
    height: 250px;
    padding-top: 40px;
}
.error .container-error-404 .clip {
    display: inline-block;
    transform: skew(-45deg);
}
.error .clip .shadow {
    overflow: hidden;
}
.error .clip:nth-of-type(2) .shadow {
    overflow: hidden;
    position: relative;
    box-shadow: inset 20px 0px 20px -15px rgba(150, 150, 150, 0.8),
        20px 0px 20px -15px rgba(150, 150, 150, 0.8);
}

.error .clip:nth-of-type(3) .shadow:after,
.error .clip:nth-of-type(1) .shadow:after {
    content: "";
    position: absolute;
    right: -8px;
    bottom: 0px;
    z-index: 9999;
    height: 100%;
    width: 10px;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(173, 173, 173, 0.8),
        transparent
    );
    border-radius: 50%;
}
.error .clip:nth-of-type(3) .shadow:after {
    left: -8px;
}
.error .digit {
    position: relative;
    top: 8%;
    color: white;
    background: #07b3f9;
    border-radius: 50%;
    display: inline-block;
    transform: skew(45deg);
}
.error .clip:nth-of-type(2) .digit {
    left: -10%;
}
.error .clip:nth-of-type(1) .digit {
    right: -20%;
}
.error .clip:nth-of-type(3) .digit {
    left: -20%;
}
.error h2 {
    color: #a2a2a2;
    font-weight: bold;
    padding-bottom: 20px;
}
.error .msg {
    position: relative;
    z-index: 9999;
    display: block;
    background: #535353;
    color: #a2a2a2;
    border-radius: 50%;
    font-style: italic;
}
.error .triangle {
    position: absolute;
    z-index: 999;
    transform: rotate(45deg);
    content: "";
    width: 0;
    height: 0;
}

@media (max-width: 767px) {
    .error .clip .shadow {
        height: 100px;
    }
    .error .clip:nth-of-type(2) .shadow {
        width: 80px;
    }
    .error .clip:nth-of-type(1) .shadow,
    .error .clip:nth-of-type(3) .shadow {
        width: 100px;
    }
    .error .digit {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 52px;
    }
    .error h2 {
        font-size: 24px;
    }
    .error .msg {
        top: -110px;
        left: 15%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
    }
    .error span.triangle {
        top: 70%;
        right: -3%;
        border-left: 10px solid #535353;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }
    .error .container-error-404 {
        height: 150px;
    }
}
@media (max-width: 380px) {
    .error .container-error-404 {
        width: 280px !important;
    }
}
</style>
